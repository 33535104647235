import type { FieldValues, UseFormSetValue } from "react-hook-form";

/** Similar to react-hook-form except can also return `undefined` (eg. while initialization is in progress) */
export type GetField<TFields extends FieldValues> = <TPath extends keyof TFields>(
    name: TPath,
) => TFields[TPath] | undefined;

/** Re-implement react-hook-form's {@link UseFormSetValue} to get suitable variance on {@link TFields}. */
export type SetField<out TFields extends FieldValues> = <TPath extends keyof TFields, TValue extends TFields[TPath]>(
    name: TPath,
    value: TValue,
) => void;

export interface IFormModel<T extends FieldValues> {
    get: GetField<T>;
    set: SetField<T>;
}

/** Utility function to make react-hook-form {@link UseFormSetValue} and {@link SetField} types match */
export function castSetter<TFields extends FieldValues>(setter: UseFormSetValue<TFields>) {
    return setter as SetField<TFields>;
}

// NB: Old versions that are compatible with react-hook-form, but unnecessarily complicated, since they support setting non-top-level keys

// export type GetField<TFieldValues extends FieldValues> = <TFieldName extends FieldPath<TFieldValues>>(
//     name: TFieldName,
// ) => FieldPathValue<TFieldValues, TFieldName> | undefined;

// export type SetField<out TFields extends FieldValues> = <
//     TPath extends FieldPath<TFields>,
//     TValue extends FieldPathValue<TFields, TPath>,
// >(
//     name: TPath,
//     value: TValue,
// ) => void;
